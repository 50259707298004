import React from 'react'
import styled from 'styled-components'

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  top: 0;
  left: 0;
  z-index: 9;
`

const HeroSection = styled.section``

const HeroContent = styled.div`
  display: flex;
  height: 150px;
  line-height: 1;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  z-index: 10;
`

const Hero = ({ id, image, logo, heading, subheading }) => {
    return (
        <HeroSection id={id} className="full-width-image full-height margin-top-0"
            style={{
                backgroundImage: `url(${
                !!image.childImageSharp ? image.childImageSharp.fluid.src : image
                })`,
                backgroundPosition: `center`,
                backgroundAttachment: `fixed`,
            }}
            >
            <Overlay/>
            <HeroContent>
                <h1 className="has-text-weight-light has-text-white mb-3 is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
                    <img src={logo} alt={heading}/>
                    {heading}
                </h1>
                <h2 className="has-text-weight-bold has-text-white is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
                    {subheading}
                </h2>
            </HeroContent>
        </HeroSection>
    )
}

export default Hero
