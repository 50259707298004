import React from 'react'

const Location = ({ id, className }) => {

    const classes = className ? `location ${className}` : 'location'

    return (
        <div className={classes}>
            <h2 className='mb-2'>Dove Siamo</h2>
            <p>
                Via Donatori di Sangue 2<br/>
                <b>Castiglione Delle Stiviere, 46043 - MN</b>
            </p>
            <a href="https://goo.gl/maps/KnDvjj8pndgbhwC58" rel="noopener noreferrer" target='_blank' className="button btn-primary">
                Ottieni Indicazioni
            </a>
        </div>
    )
}

export default Location;