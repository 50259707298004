import React from 'react'

const MenuLink = ({ id, className }) => {

    const classes = className ? `section pb-0 ${className}` : 'section pb-0'

    return (
        <section id={id} className={classes}>
            <div className="container">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <div className="notice">
                            <div className="notice-content content">
                                <h2 className='mt-0 mb-2'>Menù Digitale</h2>
                                <a href="https://www.digital-menu.cloud/bettys" rel="noopener noreferrer" target='_blank' className="button btn-primary">
                                    Apri il menù
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MenuLink;