import React from 'react'
import Markdown from 'react-markdown'

const Section = ({ id, heading, subheading, children, className }) => {

    const classes = className ? `section ${className}` : 'section'

    return (
        <section id={id} className={classes}>
            <div className="container">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <div className="content">
                            {heading ? <h2 className="title">{heading}</h2> : ''}
                            {subheading ? <Markdown source={subheading} escapeHtml={false}/> : ''}
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section
