import React from 'react'
import styled from 'styled-components'

const SplitSection = styled.section`
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

const SplitHalf = styled.div`
    position: relative;
`

const SplitHalfContent = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 10%;
    color: #fff;
    background: rgba(0,0,0,.5);

    h2{
        font-size: 2rem;
        font-weight: 700;

        @media (max-width: 768px) {
            font-size: 1.5rem;
        }
    }

    p{
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
            font-size: 1.2rem;
        }
    }
`

const List = styled.ul`
`

const ListItem = styled.li`
    list-style: none;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: .25rem;

    &:last-child{
        margin-bottom: 0;
    }

    &::before{
        display: inline-block;
        content: '\\25A0';
        color: #E2383A;
        margin-right: .5rem;
    }
`

const Split = ({ id, events }) => {
    return (
        <SplitSection id={id}>
            {events.items.map(event => {

                const backgroundImage = {
                    backgroundImage: `url(${
                        !!event.image.childImageSharp ? event.image.childImageSharp.fluid.src : event.image
                    })`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: `center center`,
                    backgroundSize: `cover`,
                }
                
                return (
                    <SplitHalf key={event.title} style={backgroundImage}>
                        <SplitHalfContent>
                            <h2>{event.title}</h2>
                            <p>{event.description}</p>
                            <List>
                                {event.list.map(item => (
                                    <ListItem key={item.text}>
                                        {item.text}
                                    </ListItem>
                                ))}
                            </List>
                        </SplitHalfContent>
                    </SplitHalf>
                )
            })}
        </SplitSection>
    )
}

export default Split
