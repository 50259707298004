import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import CookieConsent from "react-cookie-consent";
import Layout from '../components/Layout'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import Location from '../components/Location'
import MenuLink from '../components/MenuLink'
import Section from '../components/Section'
import Split from '../components/Split'
// import Menu from '../components/Menu'
import Timetable from '../components/Timetable'
// import Download from '../components/Download'

import logo from '../img/logo_light.svg'

// import ReactGA from "react-ga";
import ReactGA from "react-ga4";

export const IndexPageTemplate = ({
  image,
  title,
  subheading,
  mainpitch,
  pdf,
  menu,
  events,
  timetable
}) => (
  <div>
    <Hero
      id="home" image={image}
      title={title}
      logo={logo}
      heading={title}
      subheading={subheading}
    />
    <MenuLink id="menu"/>
    <Section
      id="orari"
      heading={mainpitch.title}
      subheading={mainpitch.description}
    >
      {/* <Timetable timetable={timetable} className='mt-8'/> */}
      <Timetable timetable={timetable} className='mt-8'/>
      <Location className='mt-8'/>
    </Section>
    {/* {menu && <Section id="menu" heading={menu.title} subheading={menu.description} className="food-section">
      <Download file={pdf} />
      <Menu list={menu.list} />
    </Section>} */}
    {events && <Split id="contatti" events={events}/>}
    <Footer/>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  menu: PropTypes.object,
  events: PropTypes.object,
}

const IndexPage = ({ data, location }, ...props) => {
  const { frontmatter } = data.markdownRemark

  // Google Analytics
  //ReactGA.initialize('UA-61739805-1');
  //ReactGA.initialize('UA-254127136-1');
  //ReactGA.pageview(location.pathname + location.search);

  // 349530713 G-Q2PKBDMB35
  ReactGA.initialize('G-Q2PKBDMB35');
  ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });


  return (
    <Layout>
      <CookieConsent
        location="bottom"
        buttonText="Accetto"
        cookieName="privacyCookie"
        style={{ background: "#fafafa", fontSize: "1rem", color: "#303030", alignItems: 'center', lineHeight: 1.5, margin: 0, padding: '.5rem 1rem', boxShadow: '0 -1px 3px rgba(0,0,0,.1)' }}
        buttonStyle={{ color: "#fff", background: '#E2383A', letterSpacing: 0, fontSize: ".85rem", fontWeight: 600, borderRadius: '4px', padding: '0.5rem 1.5rem', margin: 0 }}
        expires={150}>
        <span><b>Utilizziamo i cookie per essere sicuri che tu possa avere la migliore esperienza sul nostro sito.</b></span><br/>
        <a href="/privacy"><b>Maggiori informazioni</b></a>
      </CookieConsent>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        menu={frontmatter.menu}
        events={frontmatter.events}
        pdf={frontmatter.pdf}
        timetable={frontmatter.timetable}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        timetable,
        pdf{
          absolutePath
          relativePath
          publicURL
        },
        menu{
          title
          description
          list{
            image{
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            items{
              title
              text
              price
            }
            notes
          }
        }
        events{
          items{
            title,
            image{
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            },
            description,
            list{
              text
            }
          }
        }
      }
    }
  }
`
