import React from 'react'

import logo from '../img/logo_light.svg'
import FacebookIcon from '../img/social/facebook'
import InstagramIcon from '../img/social/instagram'
import WhatsAppIcon from '../img/social/whatsapp'

import Location from '../components/Location'
// import Timetable from '../components/Timetable'

const Footer = ({ id }) => {
  return (
    <footer id={id} className="footer has-text-white-ter">
      <div className="content has-text-centered has-text-white-ter">
        <div className="container has-text-white-ter">
          <div className="columns">
            <div className="column is-6 contacts">
            <img src={logo} alt="Betty's Restaurant" style={{ width: '14em', height: '10em' }}/>
              <h4 className="has-text-white mb-2">Contattaci</h4>
              <h5 className="has-text-white my-1">
                <a href="mailto:bettysrestaurantcastiglione@gmail.com">
                  bettysrestaurantcastiglione<span className="email-domain">@gmail.com</span>
                </a>
              </h5>
              <h5 className="has-text-white">
                <a href="tel:+393383155295">+39 338 3155295</a><br />  
                <a href="tel:+390376639767">+39 0376 639767</a>  
              </h5>
              <div className="social">
                <a title="facebook" href="https://facebook.com/bettyscastiglione/" rel="noopener noreferrer" target="_blank">
                  <FacebookIcon/>
                </a>
                <a title="instagram" href="https://instagram.com/bettysrestaurant/" rel="noopener noreferrer" target="_blank">
                  <InstagramIcon/>
                </a>
                <a title="whatsapp" href="https://wa.me/393383155295" rel="noopener noreferrer" target='_blank'>
                   <WhatsAppIcon/>
                </a>
              </div>
            </div>             
            <div className="column is-6">
              {/* <Timetable timetable={timetable}/> */}
              <Location className='mt-8'/>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright content py-6">
        <p className="mb-0">
          <b>Betty's di Pezzini Claudio</b> — P.IVA 02448330205 C.F. PZZCLD81R02C312E</p>
        © {new Date().getFullYear()} All Rights Reserved 
      </div>
    </footer>
  )
}

export default Footer;