import React from 'react'
import Markdown from 'react-markdown'

const Timetable = ({ id, className, timetable }) => {

    const classes = className ? `timetable ${className}` : 'timetable'

    return (
        <div className={classes}>
            <h2 className='mb-2'>Orari di Apertura</h2>
            <Markdown source={timetable} escapeHtml={false}/>
        </div>
    )
}

export default Timetable;